<script>
import Layout from '@/views/layouts/main'
import { ref, onMounted } from 'vue'
import { createNotificationTemplate, updateNotificationTemplate, showNotificationTemplate } from './api'
import { useRoute, useRouter } from 'vue-router'
import Notify from '@/notify'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const term = ref({
      name: '',
      title: '',
      content: '',
      short_description: null,
      type: 'DEFAULT'
    })

    const submitTermForm = async () => {
      try {
        if (route.params.id) {
          await updateNotificationTemplate(route.params.id, term.value)
        } else {
          await createNotificationTemplate(term.value)
        }
        Notify.create({
          title: 'Dados salvos com sucesso!',
          timer: 1500
        })
        router.push({ name: 'notifications_templates' })
      } catch (error) {
        Notify.create({
          title: 'Erro ao salvar dados!',
          timer: 1500
        })
      }
    }

    const getNotification = () => {
      showNotificationTemplate(route.params.id)
        .then((data) => {
          term.value = data
        })
        .catch((e) => {
          Notify.create({
            title: 'Erro ao Buscar Template de Notificação!',
            timer: 1500
          })
        })
    }

    onMounted(() => {
      if (route.params.id) {
        getNotification()
      }
    })

    return {
      route,
      term,
      submitTermForm
    }
  },
  components: {
    Layout
  }
}
</script>

<template>
  <Layout :pagetitle="`${(route.params.id ? 'Editar' : 'Adicionar')} Notificação`">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="p-4 border-top">
            <form class="was-validated" @submit.prevent="submitTermForm">
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="name" class="form-label">Nome</label>
                    <input id="name" v-model="term.name" class="form-control" placeholder="Nome" required />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="title" class="form-label">Título</label>
                    <input id="title" v-model="term.title" class="form-control" placeholder="Título" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="short_description" class="form-label">Descrição Curta</label>
                    <textarea id="short_description" v-model="term.short_description" class="form-control" placeholder="Descrição Curta"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="content" class="form-label">Conteúdo</label>
                    <textarea id="content" v-model="term.content" class="form-control" placeholder="Conteúdo" required></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="type" class="form-label">Tipo</label>
                    <select id="type" v-model="term.type" class="form-select" required>
                      <option value="DEFAULT">Padrão</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <router-link :to="{ name: 'notifications_templates' }" class="btn btn-secondary me-2">
                  <i class="bx bx-left-arrow-circle me-1" />Voltar
                </router-link>
                <button class="btn btn-primary" type="submit">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
